import React, { Component } from 'react';
import '../App.css';
import { Box } from '@mui/material';

/*
background: rgb(49,139,127);
background: linear-gradient(145deg, rgba(49,139,127,1) 0%, rgba(7,91,99,1) 39%, rgba(106,106,144,1) 100%);
*/

const Footer = () => {


  return (
    <Box className="Footer">
        <div>Made with &lt;3 from Quintan Neville</div>
    </Box>
  );
}

export default Footer;