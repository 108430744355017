import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Box, Button, Divider, IconButton, LinearProgress, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useApi } from "../../contexts/ApiProvider";
import { useNavigate } from "react-router-dom";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import { AddOrgModal } from "../Organizations/AddOrgModal";

// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

const ProfileStatusButton = () => {
  
  const { user, logout, loginWithRedirect, isAuthenticated } = useAuth0();
  const {orgInfo, profile} = useApi();
  const {navigate} = useNavigate();

  const [addOrgModalOpen, setAddOrgModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleJoinOrg = () => {
    
  }

  const handleSwitchOrg = () => {
    console.log("Changing Org")
  }

  return (
    
    <Box width={150}>
      {
        isAuthenticated &&
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Your Account">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar /> Profile
            </MenuItem>
            
            <Divider >Organizations</Divider>            
            {
              orgInfo.organizations.map((org) => {
                return (
                  <MenuItem key={org.id} onClick={() => handleSwitchOrg(org)}>
                    <ListItemIcon mr={3}>
                      <Box component={'img'} src={org.logo_url} sx={{width:'30px', height:'30px', borderRadius: "5px"}}/>
                    </ListItemIcon>
                    {org.name}
                  </MenuItem>
                )
              })
            }

            {
              orgInfo.organizations.length == 0 &&
              <MenuItem disabled={true}>
                No Organizations Added
              </MenuItem>
            }
            
            <Divider />
            
            
            <MenuItem onClick={() => setAddOrgModalOpen(true)}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Join Another Organization
            </MenuItem>
          
            <MenuItem onClick={() => logout()} style={{ color: 'red' }}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </>
      }
      <AddOrgModal open={addOrgModalOpen} onOpen={() => setAddOrgModalOpen(true)} onClose={() => setAddOrgModalOpen(false)}/>
    </Box>
  )
};

export default ProfileStatusButton;