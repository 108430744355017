import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useRequests } from "../../contexts/RequestContext";
import { useApi } from "../../contexts/ApiProvider";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { AddApps, AddOrganization, FinishSetup, SetupWelcome, VerifyComplete } from "../ProfileSetupComponents";

const CompleteProfilePrompts = () => {

  const steps = ['Introduction', 'Join an Organization', 'Review Software',]; 

  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const {authenticatedFetch} = useRequests();

  const handleProfileGet = () => {
    authenticatedFetch('/api/account').then( d => console.log(d.data)).catch( e => console.error(e))
  }

  const {orgInfo} = useApi();


  /* STEPPER DETAILS */
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 69;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <SetupWelcome onNext={() => setActiveStep(1)}/>
      case 1:
        return <AddOrganization onPrev={()=>setActiveStep(0)} onNext={() => setActiveStep(2)}/> 
      case 2:
        return <AddApps onPrev={()=>setActiveStep(1)} onNext={() => setActiveStep(3)}/>
      case 3:
        return <VerifyComplete onPrev={()=>setActiveStep(2)} onNext={() => setActiveStep(4)}/>
      default:
        return <FinishSetup/>
    }
  }

  return (
    <Box >
      <Typography py={3} variant="h2" sx={{textAlign:"center"}}>Complete your profile</Typography>

      {/* Stepper Logic */}

      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>


        {/* Completion Page */}
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              
              <FinishSetup/>

            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              {/* <Button onClick={handleReset}>Reset</Button> */}
            </Box>
          </>


          ) : (
            <>
            <Box>
              {/* Step handling is within each content piece */}
              {getStepContent()}
            </Box>
          </>
        )}
      </Box>
    </Box>
    )
};

export default CompleteProfilePrompts;