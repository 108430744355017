import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import { useRequests } from './RequestContext';

const ApiContext = createContext();

export const ApiProvider = ({ children, apiBase = "" }) => {

  const { user, isAuthenticated } = useAuth0();
  const {authenticatedFetch} = useRequests();
  // Information about the user's stuff

  const [profile, setProfile] = useState(null);
  
  const [orgInfo, setOrgInfo] = useState({
    organizations: [],
    currentOrganization: null,
    apps: []

  });
  const [documents, setDocuments] = useState([]);
  const [reports, setReports] = useState([]);
  const [apps, setApps] = useState([]);

  const [blockingLoad, setBlockingLoad] = useState(false);

  const [messages, setMessages] = useState([
    // {severity: "error", message: "Could not authenticate"},
    // {severity: "success", message: "Could not authenticate"} 
  ]);

  const loadProfile = () => {
    authenticatedFetch('/api/account')
    .then( (d) => {
      console.log("LOADED PROFILE", d.data)
      setProfile(d.data)
    })
    .catch(
      e => setMessages([...messages, {severity: "error", message: "Could not load or initialize profile"}])
    )
  }

  const loadApps = () => {
    authenticatedFetch('/api/organizations/current/apps')
    .then( (d) => {
      console.log("LOADED PROFILE", d.data)
      setApps(d.data)
    })
    .catch(
      e => setMessages([...messages, {severity: "error", message: "Could load apps"}])
    )
  }

  const loadDocuments = () => {
    authenticatedFetch('/api/organizations/current/documents')
    .then( (d) => {
      console.log("LOADED PROFILE", d.data)
      setDocuments(d.data)
    })
    .catch(
      e => setMessages([...messages, {severity: "error", message: "Could not load documents"}])
    )
  }

  const loadReports = () => {
    authenticatedFetch('/api/organizations/current/reports')
    .then( (d) => {
      console.log("LOADED PROFILE", d.data)
      setReports(d.data)
    })
    .catch(
      e => setMessages([...messages, {severity: "error", message: "Could not load reports"}])
    )
  }



  const loadCurrentOrgs = () => {
    authenticatedFetch('/api/account/organizations')
    .then( (d) => {
      console.log("LOADED PROFILE", d?.data)
      setOrgInfo({...orgInfo, organizations: d.data})
    })
    .catch(
      e => {
        console.error("Couldn't load org info", e)
        setMessages([...messages, {severity: "error", message: "Could not load your organizations"}]);
      }
    )
  }

  const findOrgByShareCodeAsync = (shareCode) => {
    authenticatedFetch(`/api/organizations/find/${shareCode}`)
  }

  useEffect(() => {
    if(isAuthenticated) {
      console.log("User is authenticated - Load org info now");
      loadProfile();
      loadCurrentOrgs();
      loadDocuments()
      loadApps();
      loadReports();
    } else {
      console.log("User is unauthenticated - Dump")
    }
  }, [isAuthenticated]);

  const handleClose = (index) => (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessages(messages.filter((msg, i) => i !== index));
  };

  const getOrgInfo = () => {
    authenticatedFetch('/api/account/organizations')
      .then( d => setOrgInfo({...orgInfo, organizations: d.data}))
      .catch( e => console.error(e))
  }

  const attemptToJoinOrg = (orgCode) => {
    authenticatedFetch(`/api/account/organizations/join/${orgCode}`, {method:'POST'})
    .then( d => setOrgInfo({...orgInfo, organizations: d.data}))
    .catch( e => console.error(e))
  }

  const updateProfile = () => {

  }

  return (
    <ApiContext.Provider value={{ 
      orgInfo,
      setOrgInfo,

      profile,

      loadProfile,
      loadCurrentOrgs,
      documents,
      apps,
      reports
      
      
    }}>
      {children}

      {/* Error & Message bar */} 
      {
        messages.map((error, index) => {
          return (
            <Snackbar
              key={index}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={true}
              autoHideDuration={6000}
              onClose={handleClose(index)}
              message={error.message}
              severity={error.severity}
            />
          )
        })
      }
      
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);

