import React, { Component, useEffect, useMemo, useState } from 'react';
import '../App.css';
import { Box, Button, Card, CardContent, CardHeader, CardMedia, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthChecker from './AuthChecker';
import { useRequests } from '../contexts/RequestContext';
import image from './images/backdrop.jpg'
import Dashboard from './Dashboard';
import { NavLink } from 'react-router-dom';
import CompleteProfilePrompts from './Auth/CompleteProfilePrompts';
import { useApi } from '../contexts/ApiProvider';
/*
*/

const CompleteProfileInterjection = ({account}) => {
  const [reports, setReports] = useState([]);
  
  
  
  const {user, isAuthenticated, loginWithRedirect, logout} = useAuth0();
  const {authenticatedFetch} = useRequests();
  
  const {orgInfo, documents, applications, profile, loadProfile, loadCurrentOrgs} = useApi();

  useEffect( () => {
    loadProfile();
    loadCurrentOrgs();
  },[])


//   const {orgInfo} = useApi();
  const theme = useTheme();

  return (
      <Container mt={'80px'} maxWidth="md">
        {
            orgInfo.organizations.length == 0 &&
            <Card sx={{display:'flex', flexDirection:'row', minHeight:'500px'}}>

                <CardMedia
                    component="img"
                    media="picture"
                    alt="Contemplative Reptile"
                    sx={{display:'block', position:'relative', minWidth:'100px', maxWidth:'5%', height:'500px'}}
                    image={image}
                
                >

                </CardMedia>
                <CardContent sx={{flex: 1, alignContent: 'center', display:'block', alignText:'left'}}>
                    {
                        isAuthenticated && user &&
                        <Stack spacing={2}>
                            <CompleteProfilePrompts/>
                        </Stack>
                    }
                </CardContent>
            </Card>
        }
    </Container>
  );
}

export default CompleteProfileInterjection;