import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import ProfilePopoutButton from "./ProfilePopoutButton";
import { useRequests } from "../../contexts/RequestContext";
import ProfileStatusButton from "./ProfileStatusButton";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const {isAuthenticated} = useRequests();

  const handleLogin = () => {
    loginWithRedirect();
  };


  if(!isAuthenticated){
    return <Button onClick={() => handleLogin()} color='primary' variant="contained" sx={{ px:3, py: 1 }}>Sign In</Button>;
  } else {
    return <ProfileStatusButton/>;
  }
};

export default LoginButton;