import React, { Component, useEffect, useMemo, useState } from 'react';
import '../App.css';
import { Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthChecker from './AuthChecker';
import { useRequests } from '../contexts/RequestContext';
import { DeleteTwoTone, Description, Download, DownloadDoneTwoTone, DownloadOutlined } from '@mui/icons-material';
import AppsList from './Apps/AppsList';
import ReportsList from './Apps/ReportsList';
import DocumentsList from './Apps/DocumentsList';
/*
*/

const Dashboard = ({account}) => {
  const [reports, setReports] = useState([]);
  
  const {user, isAuthenticated} = useAuth0();
  const {authenticatedFetch, fetch} = useRequests();
  const theme = useTheme();
  
  useEffect( () => {
    authenticatedFetch("/api/reports").then((d) => {
        console.log(d.data);
        setReports(d.data)
      })
  },[])
  return (
      <Container mt={'80px'}>
        <Stack spacing={2}>
          <Box>
          <Card>
            <CardHeader title="Documents"/>
            <CardContent>
              <DocumentsList/>
            </CardContent>
          </Card>
        </Box>
        <Box>
          <Grid container spacing={2}>
              <Grid item md="5">
                  <Card>
                      <CardHeader title="Reports"/>
                      <CardContent>
                        <ReportsList />
                      </CardContent>
                  </Card>
                  
              </Grid>


              <Grid item md="7">
                  <Card>
                      <CardHeader title="Apps"/>
                      <CardContent>
                        <AppsList/>
                      </CardContent>
                  </Card>
              </Grid>
          </Grid>
        </Box>
        </Stack>
      </Container>
  );
}

export default Dashboard;