import React, { Component, useEffect, useMemo, useState } from 'react';
import '../App.css';
import { Box, Button, Card, CardContent, CardHeader, CardMedia, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthChecker from './AuthChecker';
import { useRequests } from '../contexts/RequestContext';
import image from './images/backdrop.jpg'
import Dashboard from './Dashboard';
import { NavLink } from 'react-router-dom';
import CompleteProfilePrompts from './Auth/CompleteProfilePrompts';
import { useApi } from '../contexts/ApiProvider';
/*
*/

const Homepage = ({account}) => {
  const [reports, setReports] = useState([]);
  
  
  
  const {user, isAuthenticated, loginWithRedirect, logout} = useAuth0();
  const {authenticatedFetch} = useRequests();
  
  const {orgInfo, documents, applications, profile, loadProfile, loadCurrentOrgs} = useApi();

  useEffect( () => {
    loadProfile();
    loadCurrentOrgs();
  },[])


//   const {orgInfo} = useApi();
  const theme = useTheme();

  return (
    <Container mt={'80px'} maxWidth="md">
        <Dashboard/>
    </Container>
  );
}

export default Homepage;