import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { styled } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './Auth/LoginButton';
import LogoPx from "./images/dog-150x150.png";

/* Implementing Auth0 Here:
   ========================
     https://auth0.com/docs/quickstart/spa/react/01-login#add-login-to-your-application
*/

const pages = [
  // {label: 'Github', url: 'https://github.com/qneville'},
  // {label: 'Readme', url: 'https://github.com/qneville/react-rails-auth0-boilerplate/blob/master/README.md'},
  // {label: 'Blog', url:'https://blog.rollypopsoft.com'}
];
const settings = ['Profile', 'Account', 'Admin', 'Logout'];

function Navbar({account}) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const authenticated = React.useMemo( () => {
    return account != null;
  }, [account])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const GlassBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: "rgba(255,255,255,0.5)", // Use a different theme color
    backdropFilter: 'blur(20px)',
    boxShadow: 'none', // Remove shadow
    '& .MuiToolbar-root': { // Target the Toolbar component inside AppBar
      minHeight: '44px',
      width: 'calc(100%-30px)',
      zIndex: 100
    },
  }));


  const handleExternalLink = (url) => {
    window.open(url, '_blank');
  }

  return (
      <GlassBar position="fixed">
        <Container>
          <Toolbar disableGutters>
            <Box component={"img"} src={LogoPx}  sx={{ height: 50, width: "auto", imageRendering: "pixelated", display: { xs: 'none', md: 'flex' }, mr: 1 }}/> 

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem target='_blank' key={page.label} onClick={()=>handleExternalLink(page.url)}>
                    <Typography textAlign="center">{page.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{flex:1}}>
              <Box component={"img"} src={LogoPx}  sx={{ imageRendering: "pixelated", display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  onClick={()=>handleExternalLink(page.url)}
                  key={page.label}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.label}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {
                authenticated == true &&
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
              }
              {
                authenticated == false &&
                <LoginButton/>
              }
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </GlassBar>
  );
}
export default Navbar;