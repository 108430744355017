import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard';
import Footer from './components/Footer';
import { blueGrey, indigo } from '@mui/material/colors';
import { Box, CircularProgress, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter, Outlet, Route, RouterProvider, Switch, createBrowserRouter, redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import Homepage from './components/Homepage';
import ProtectedRoute from './Routers/ProtectedRoute';
import { RequestProvider } from './contexts/RequestContext';
import { ApiProvider, useApi } from './contexts/ApiProvider';
import CompleteProfileInterjection from './components/CompleteProfileInterjection';
import SignIn from './components/SignIn';

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: blueGrey,
  },
});

const Loader = () => {
  return (
    <Stack alignItems="center" style={{textAlign:'center'}}>
        <CircularProgress sx={{flex: 1,alignSelf: 'center'}}/>
    </Stack>
  )
} 

export default function App() {

  const { user, isAuthenticated, isLoading, logout} = useAuth0();
  
  const { profile, orgInfo, documents, applications, loadProfile, loadCurrentOrgs } = useApi();
  
  const interjectionLoadingOrContinueToElement = (element) => {

    if(profile && profile.account_completed_at != null) { 
      return element
    }
    else if (profile && profile.account_completed_at == null) {
      return CompleteProfileInterjection;
    } else {
      return Loader
    }
  }

  if(isLoading) {
    return Loader
  }

  if(!isAuthenticated) {
    return (
      <div className="App">
        <Box className="Hero">
          <ThemeProvider theme={theme}>
            <SignIn/>
          </ThemeProvider>
        </Box>
      </div>
    )
  }
  
  const router = createBrowserRouter([
    {
      id: "root",
      path: "/",
      Component: Layout,
      children: [
        {
          index: true,
          Component: interjectionLoadingOrContinueToElement(Homepage),
        }
      ],
    },
    {
      id: "admin",
      path: "/admin",
      Component: Layout,
      children: [
        {
          index: true,
          Component: Dashboard,
        }
      ],
    },
    {
      id: "complete_profile",
      path: "/complete_profile",
      Component: Layout,
      children: [
        {
          index: true,
          Component: Dashboard,
        }
      ],
    },
    {
      path: "/logout",
      async action() {
        // We signout in a "resource route" that we can hit from a fetcher.Form
        await logout({ logoutParams: { returnTo: window.location.origin } });
        return redirect("/");
      },
    }
  ]);

  return (
    
    <RouterProvider router={router} fallbackElement={Loader} />

  );
}

function Layout() {
  return (
    
    <div className="App">

      <ThemeProvider theme={theme}>
        <Box position={'fixed'} top={10} right={10} left={10}>
          <Navbar account={null}></Navbar>
        </Box>
        <Box className="Hero">
          <Outlet/>
        </Box>
        <Footer></Footer>
      </ThemeProvider>
    </div>
      
  );
}

const Sidebar = (props) => {
  return (
  <List>
      {['All mail', 'Trash', 'Spam'].map((text, index) => (
      <ListItem key={text} disablePadding>
        <ListItemButton>
          <ListItemText primary={text} />
        </ListItemButton>
      </ListItem>
    ))}
  </List>
  )
}

function Admin(props) {

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { window } = props;
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };


  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Box position={'fixed'} top={10} right={10} left={10}>
          <Navbar account={null}></Navbar>
        </Box>
        <Box className="Hero">
          {/* Use responsive drawers */}
          <Box
            component="nav"
            aria-label=""
          >
          </Box>
          <Outlet/>
        </Box>
        <Footer></Footer>
      </ThemeProvider>
    </div>
    
  );
}

