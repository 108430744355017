import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { RequestProvider } from './contexts/RequestContext';
import { ApiProvider } from './contexts/ApiProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
        debug={true}
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={ window.location.origin }
        useRefreshTokens={true}
        cacheLocation="localstorage"
        authorizationParams={{
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin,
          scope: "openid profile email offline_access",
        }}
    >
      <RequestProvider 
        apiBase={process.env.REACT_APP_HUB_API_URL}
      >
        <ApiProvider>
            <App /> 
        </ApiProvider>
      </RequestProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
