import React, { useState } from "react";
import { Box, Button, LinearProgress, Stack, TextField, Typography } from "@mui/material";
import AnimatedModal from "../Shared/AnimatedModal";
import { Axios } from "axios";
import { useRequests } from "../../contexts/RequestContext";
import { useApi } from "../../contexts/ApiProvider";


export const AddOrgModal = ({open, onOpen, onClose}) => {

    const [joinCode, setJoinCode] = useState("");
    const [pendingOrg, setPendingOrg] = useState(null);
    const [lookupError, setLookupError] = useState(null);

    
    const {fakeFetch, authenticatedFetch} = useRequests();

    const {orgInfo, setOrgInfo} = useApi();

    const handleOnClose = () => {
        // setModalOpen(false);
        onClose();
    };

    const handleOnOpen = () => {
        // setModalOpen(true);
        onOpen();
    };


    const onLookUpOrganizaition = () => {
        console.log("Looking up organization")
        authenticatedFetch(`/api/organizations/find/${joinCode}`).then( d => {
            if(d.data != null) {
                console.log(d.data);
                setPendingOrg(d.data)
                setJoinCode("");
            } else {
                setLookupError("Organization not found")
            }
        }).catch( e => {
            console.error(e);
        })

    }

    const onConfirmJoinOrganization = () => {
        authenticatedFetch(`/api/account/organizations/join/${pendingOrg.share_code}`, {method:'POST'}).then( d => {
            console.log(d.data);
            setPendingOrg(null);
            setJoinCode("");
            onClose();
            setOrgInfo({...orgInfo, organizations: [d.data]})

        }).catch( e => {
            console.error(e);
        })
    }

    return (
      <>

        <AnimatedModal
            sx={{displayPrint: 'none'}}
            open={open}
            onClose={handleOnClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            title="Join an organization"
        >
            {
                lookupError &&
                    <Button onClick={() => setLookupError(null)}>Try again</Button>
            }
            {
                !lookupError && !pendingOrg &&
                <Stack spacing={2}>
                    <Typography>Please enter the join code below</Typography>
                    
                    <TextField id="join-code" label="Join Code" variant="outlined" fullWidth value={joinCode} onChange={(e) => setJoinCode(e.target.value)} />
                    <Button variant="contained" color="primary" onClick={() => onLookUpOrganizaition()}>Look It Up</Button>

                </Stack>
            }
            {
                !lookupError && pendingOrg &&
                <Stack spacing={2}>
                <Typography>Is this the one?</Typography>
                
                <Stack spacing={2} direction={'row'}>
                    <img src={pendingOrg.logo_url} style={{width:'100px', height:'100px'}}/>
                    <Typography variant="h4">{pendingOrg.name}</Typography>

                </Stack>
                
                <Button variant="contained" color="primary" onClick={() => onConfirmJoinOrganization()}>Confirm</Button>

            </Stack>
            }
            
        </AnimatedModal>
      </>
        
    )
};